<template>
  <div class="contact">
    <Header :category-list="categoryList" :from="name" />

    <div class="container" style="background-color: #f7f9fa">
      <div class="top-box"></div>
      <p class="contact-me">联系我们</p>
      <div class="contact-box">
        <div class="contact-type">
          <div>
            <img class="top-img" src="../../assets/img/mcontact2.png">
            <div class="type-text">
              邮政编码
            </div>
            <div class="type-num">
              201108
            </div>
          </div>
        </div>
        <div class="contact-type">
          <div>
            <img class="top-img" src="../../assets/img/mcontact3.png">
            <div class="type-text">
              联系电话
            </div>
            <div class="type-num">
              021-65289166
            </div>
          </div>
        </div>
        <div class="contact-type">
          <div>
            <img class="top-img" src="../../assets/img/mcontact1.png">
            <div class="type-text">
              电子邮箱
            </div>
            <div class="type-num">
              <a href="mailto:wmkx@jzsf.com">wmkx@jzsf.com</a>
            </div>
          </div>
        </div>
      </div>
      <p class="address">公司地址</p>
      <img class="map-img" src="../../assets/img/map1.png">
      <div style="display: flex;width: 100%;justify-content: center;">
        <img class="scan-img" src="../../assets/img/mewm.png">
      </div>

    </div>
    <Footer />
  </div>
</template>

<script>
  // @ is an alias to /src
  import Header from '@/components/Header.vue'
  import Footer from '@/components/Footer.vue'
  import {
    getFirstAndSecondCategoryUrl
  } from '@/api/common'

  export default {
    name: 'Contact',
    components: {
      Header,
      Footer
    },
    data() {
      return {
        name: 'Contact',
        categoryList: []
      }
    },
    mounted() {
      this.getCategory()
    },
    methods: {
      getCategory() {
        getFirstAndSecondCategoryUrl().then(res => {
          this.categoryList = res.data.datas
        })
      }
    }
  }
</script>
<style scoped lang="less">
  .top-box {
    height: 72px;
  }

  .contact-me {
    width: 100%;
    text-align: center;
    font-size: 32px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #13344b;
    line-height: 45px;
    margin: 80px 0 132px 0;
  }

  .address {
    width: 100%;
    text-align: center;
    font-size: 32px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #13344b;
    line-height: 45px;
    margin: 160px 0 80px 0;
  }

  .map-img {
    width: 1420px;
    height: 652px;
    margin: 0 auto;
  }

  .scan-img {
    width: 1353px;
    height: 583px;
    margin: 130px 0 0 88px;
  }

  .contact-box {
    height: 240px;
    width: 1420px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .contact-type {
      width: 400px;
      height: 240px;
      background: #f7f9fa;
      box-shadow: 8px 8px 16px 0px #e1eaf9;
      border-radius: 20px;
      position: relative;

      .top-img {
        position: absolute;
        top: -54px;
        left: 155px;
        width: 90px;
        height: 78px;
      }

      .type-text {
        height: 33px;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #13344B;
        line-height: 33px;
        text-align: center;
        margin-top: 78px;
      }

      .type-num {
        height: 33px;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #004899;
        line-height: 33px;
        text-align: center;
        margin-top: 13px;
      }
    }
  }

  @media screen and (max-width: 1550px) {
    .top-box {
      height: 60px;
    }

    .contact-me {
      margin: 68px 0 112px 0;
    }

    .address {
      margin: 136px 0 68px 0;
    }

    .map-img {
      width: 1200px;
      height: 551px;
    }

    .scan-img {
      width: 1143px;
      height: 493px;
      margin: 110px 0 0 74px;
    }

    .contact-box {
      height: 203px;
      width: 1200px;

      .contact-type {
        width: 338px;
        height: 203px;

        .top-img {
          position: absolute;
          top: -46px;
          left: 131px;
          width: 76px;
          height: 66px;
        }

        .type-text {
          margin-top: 66px;
        }
      }
    }
  }

  @media screen and (max-width: 750px) {
    .top-box {
      height: 60px;
    }

    .contact-me {
      margin: 38px 0 62px 0 !important;
    }

    .address {
      margin: 136px 0 38px 0;
    }

    .map-img {
      width: 98%;
      margin: 0 auto;
      height: 251px;
    }

    .scan-img {
      width: 98%;
      height: 193px;
      margin: 40px 0 0 74px;
    }

    .contact-box {
      height: 470px !important;
      display: block;
      width: 98vw !important;

      .contact-type {
        width: 90vw !important;
        height: 150px;
        margin: 60px auto;
        display: flex;
        justify-content: center;

        .top-img {
          position: absolute;
          top: -46px;
          left: 131px;
          width: 76px;
          height: 66px;
          z-index: 1000;
        }

        .type-text {
          margin-top: 36px !important;
        }
      }
    }
  }
</style>
